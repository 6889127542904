import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable";
import SearchBar from "@/components/DataTable/SearchBar";
import { deleteService, deleteServiceFee } from "@/lib/polkadotProvider/command/services";
import { mapGetters, mapState } from "vuex";
import { getOrdersData } from "@/lib/api";
import { fromEther } from "@/lib/balance-format";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
export default {
  name: "LabServices",
  components: {
    DataTable,
    SearchBar,
    Dialog,
    DialogErrorBalance,
    Button
  },
  data: () => ({
    headers: [{
      text: "Image",
      value: "info.image",
      sortable: false
    }, {
      text: "Name",
      value: "info.name"
    }, {
      text: "Description",
      value: "info.description"
    }, {
      text: "Price",
      value: "info.price"
    }, {
      text: "Action",
      value: "actions",
      sortable: false,
      align: "center",
      width: "10%"
    }],
    search: "",
    services: [],
    isLoading: false,
    showDialog: false,
    isShowError: false,
    showActiveOrder: false,
    showAlert: false,
    idItemDeleted: "",
    fee: 0
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      web3: "metamask/getWeb3",
      labAccount: "substrate/labAccount"
    }),
    ...mapState({
      lastEventData: state => state.substrate.lastEventData
    }),
    computeButtonStatus() {
      var _this$labAccount, _this$labAccount$serv, _this$labAccount2;
      return ((_this$labAccount = this.labAccount) === null || _this$labAccount === void 0 ? void 0 : (_this$labAccount$serv = _this$labAccount.services) === null || _this$labAccount$serv === void 0 ? void 0 : _this$labAccount$serv.length) === 1 && ((_this$labAccount2 = this.labAccount) === null || _this$labAccount2 === void 0 ? void 0 : _this$labAccount2.verificationStatus) === "Verified";
    }
  },
  watch: {
    lastEventData(val) {
      if (val === null) return;
      if (val.method === "ServiceDeleted") {
        this.isLoading = false;
        this.showDialog = false;
        this.getServices();
      }
    }
  },
  async created() {
    await this.getServices();
  },
  methods: {
    async getServices() {
      const {
        labAccount: {
          services
        }
      } = await this.$store.dispatch("substrate/getLabAccount");
      this.services = services.reduce((filtered, service) => {
        if (service && !filtered.find(v => v.id === service.id)) filtered.push(service);
        return filtered;
      }, []);
    },
    onSearchInput(val) {
      this.search = val;
    },
    gotoDetails(_ref) {
      let {
        id
      } = _ref;
      this.$router.push({
        name: "lab-dashboard-services-detail",
        params: {
          id: id
        }
      });
    },
    getImageLink(val) {
      if (val && val != "") {
        return val;
      }
      return "https://ipfs.io/ipfs/QmaGr6N6vdcS13xBUT4hK8mr7uxCJc7k65Hp9tyTkvxfEr";
    },
    formatPrice(price) {
      const priceAndCurrency = price[0].totalPrice.replaceAll(",", "").split(" ");
      const formatedPrice = fromEther(priceAndCurrency[0], price[0].currency);
      return "".concat(Number(formatedPrice).toLocaleString("en-US"), " ").concat(price[0].currency === "USDTE" ? "USDT.e" : price[0].currency);
    },
    isIcon(imageName) {
      return imageName && (imageName.startsWith("mdi") || imageName.startsWith("$dgi"));
    },
    async getDeleteServiceFee() {
      const fee = await deleteServiceFee(this.api, this.pair, this.idItemDeleted);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async handleDeleteService() {
      try {
        this.isLoading = true;
        await deleteService(this.api, this.pair, this.idItemDeleted);
        this.showDialog = false;
      } catch (error) {
        if (error.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
        console.error(error.message);
        this.isLoading = false;
        this.showDialog = false;
      }
    },
    closeDialog() {
      this.isShowError = false;
    },
    async confirmDeleteService(item) {
      const {
        data
      } = await getOrdersData(this.pair.address, null, null, null);
      const hasActiveOrder = data === null || data === void 0 ? void 0 : data.some(order => order._source.status === "Paid" && order._source.service_id === item.id);
      if (hasActiveOrder) {
        this.showActiveOrder = true;
        return;
      }
      this.idItemDeleted = item.id;
      await this.getDeleteServiceFee();
      this.showDialog = true;
    }
  }
};