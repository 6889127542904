//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NumOfEntriesSelect from "./NumOfEntriesSelect";
export default {
  name: "DataTable",
  components: {
    NumOfEntriesSelect
  },
  props: {
    headers: Array,
    items: Array,
    searchLabel: String,
    search: String,
    additionalClass: String,
    customFilter: Function,
    sortBy: [Array, String, Number],
    sortDesc: Array,
    loading: Boolean,
    loadingText: String
  },
  data: () => ({
    entriesPerPage: 10,
    page: 1,
    pageCount: 0
  }),
  computed: {
    dataTableSearchVal: {
      get() {
        return this.search;
      },
      set(val) {
        console.log(val);
        this.$emit("input", val);
      }
    },
    computedLoadingText() {
      return this.loadingText ? this.loadingText : "Loading.. Please wait";
    }
  },
  methods: {
    setEntriesPerPage(val) {
      this.entriesPerPage = val;
    },
    defaultFilter(value, search /*item*/) {
      return value != null && search != null && typeof value !== "boolean" && value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
    },
    /**
     * Footer Helper functions
     */
    from() {
      if (this.items.length == 0) {
        return 0;
      }
      return this.entriesPerPage * (this.page - 1) + 1;
    },
    to() {
      if (this.page * this.entriesPerPage > this.items.length) {
        return this.items.length;
      }
      return this.page * this.entriesPerPage;
    },
    total() {
      return this.items.length;
    }
    /** ----------------- */
  }
};