import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogErrorBalance',{attrs:{"show":_vm.isShowError},on:{"close":_vm.closeDialog}}),_c(VContainer,[_c(VRow,[_c(VCol,[_c(VContainer,{staticClass:"d-flex flex-row-reverse"},[_c(VBtn,{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({name:'lab-dashboard-add-services'})}}},[_c(VIcon,{attrs:{"inline":"","color":"white","size":20}},[_vm._v("mdi-plus")]),_vm._v(" Add Service ")],1)],1),_c('Dialog',{attrs:{"show":_vm.showDialog,"width":400},on:{"close":function($event){_vm.showDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div')]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"d-flex justify-center pb-1 pt-1"},[_c(VImg,{attrs:{"src":require('@/assets/alert-circle.png'),"max-width":"75"}})],1),_c('div',{staticClass:"pb-1",attrs:{"align":"center"}},[_vm._v("Are you sure you want delete this service?")]),_c('div',{staticClass:"ml-5 mr-5 pb-1 d-flex justify-space-between mt-5",attrs:{"align":"center"}},[_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Estimated Transaction Weight ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(Number(_vm.fee).toFixed(4))+" DBIO ")])])]},proxy:true},{key:"actions",fn:function(){return [_c(VCol,{attrs:{"col":"12","md":"6"}},[_c('Button',{attrs:{"elevation":"2","color":"purple","dark":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("No")])],1),_c(VCol,{attrs:{"col":"12","md":"6"}},[_c('Button',{attrs:{"elevation":"2","dark":""},on:{"click":_vm.handleDeleteService}},[_vm._v("Yes")])],1)]},proxy:true}])}),_c('Dialog',{attrs:{"show":_vm.showActiveOrder,"showClose":false,"width":330},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-8"},[_vm._v("Unfinished Order")]),_c(VIcon,{staticClass:"mb-8",attrs:{"inline":"","color":"primary","size":100}},[_vm._v(" mdi-alert-circle-outline ")])],1),_c('p',[_vm._v(" You still have active orders to complete. Resume any pending orders before continuing with this process. ")])]},proxy:true},{key:"actions",fn:function(){return [_c('Button',{attrs:{"elevation":"2","color":"primary","to":{ name: 'lab-dashboard' },"dark":""}},[_vm._v(" Go to Dashboard ")])]},proxy:true}])}),_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.services,"search":_vm.search,"sort-by":'info.name',"sort-desc":[false],"loading":_vm.isLoading,"additional-class":"laporan-table"},scopedSlots:_vm._u([{key:"search-bar",fn:function(){return [_c('SearchBar',{attrs:{"label":"Search"},on:{"input":_vm.onSearchInput}})]},proxy:true},{key:"item.info.image",fn:function(ref){
var item = ref.item;
return [_c(VContainer,{attrs:{"rounded":""}},[(_vm.isIcon(item.info.image))?_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,{attrs:{"color":"#BA8DBB","size":48}},[_vm._v(" "+_vm._s(item.info.image)+" ")])],1):_vm._e(),(item.info.image)?_c(VImg,{attrs:{"src":item.info.image,"alt":item.name,"max-height":"70px","max-width":"70px"}}):_c(VImg,{attrs:{"src":require("@/assets/debio-logo.png"),"alt":item.name,"max-height":"70px","max-width":"70px"}})],1)]}},{key:"item.info.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatPrice(item.info.pricesByCurrency))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VContainer,{staticClass:"d-flex",attrs:{"justify-space-between":""}},[_c(VIcon,{attrs:{"color":"black"},on:{"click":function($event){return _vm.gotoDetails(item)}}},[_vm._v(" mdi-pencil ")]),_c(VTooltip,{attrs:{"bottom":"","disabled":!_vm.computeButtonStatus},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VIcon,{attrs:{"color":"black","disabled":_vm.computeButtonStatus},on:{"click":function($event){return _vm.confirmDeleteService(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("You need to have at least 1 service")])])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }